import { useState } from "react";

/** Nextjs */
import dynamic from "next/dynamic";

/** UI */
const LoginLayout = dynamic(() => import("@/src/layouts/LoginLayout"));
const LoginPage = dynamic(() => import("@/src/pages/Login"));
const FullpagePreloader = dynamic(() => import("@/src/shared/FullpagePreloader"));

const Login = (props) => {
    const [loading, setLoading] = useState(true);

    const handleOnLoaded = () => setLoading(false);

    return (
        <LoginLayout>
            {!!loading && <FullpagePreloader />}
            <LoginPage onLoaded={handleOnLoaded} />
        </LoginLayout>
    );
};

export default Login;
